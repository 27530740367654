import "./StudentSelectModal.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function StudentSelectModal({
  studentSelectShow,
  students,
  handleCloseStudentSelect,
  email,
  handleUpdateStudentUsername,
}) {
  const [selectedStudent, setSelectedStudent] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedStudent) {
      handleUpdateStudentUsername(selectedStudent);
    }
  };

  return (
    <>
      <Modal show={studentSelectShow} onHide={handleCloseStudentSelect}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="students-list">
            <p>
              Select a student you wish to connect with <b>{email}</b>
            </p>
            <form onSubmit={handleSubmit}>
              {students.map((student, index) => (
                <div
                  key={index}
                  className={`student mb-3 ${
                    selectedStudent === student.name ? "selected" : ""
                  }`}
                >
                  <label htmlFor={student.name} className="ms-2">
                    <h5 className="mb-1">{student.first_name} {student.last_name}</h5>
                    <h6 className="mb-0">{student.name}</h6>
                  </label>
                  <input
                    type="radio"
                    id={student.name}
                    name="student"
                    value={student.name}
                    checked={selectedStudent === student.name}
                    onChange={(e) => setSelectedStudent(e.target.value)}
                  />
                </div>
              ))}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseStudentSelect}>
            Close
          </Button> */}
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!selectedStudent}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StudentSelectModal;
