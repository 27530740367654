import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { validateOTP } from "../../axios/services/utilityService";

function OtpRegisterModal({ show, handleClose, mobileNumber, onOtpVerified }) {
  const [otp, setOtp] = useState("");

  const validate_OTP = () => {
    let valid = true;

    if (!/^\d{4}$/.test(otp)) {
      valid = false;
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate_OTP()) {
      toast.error("Enter a valid 4-digit OTP");
      return;
    }
    const response = await validateOTP(mobileNumber, otp);
    if (response.validated.message) {
      onOtpVerified();
      handleClose();
    } else {
      toast.error("Invalid OTP");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email OTP</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter the 6 digit OTP here"
                autoFocus
              />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile OTP</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter the OTP here"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OtpRegisterModal;
