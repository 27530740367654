import { axiosUtilityInstance } from "../axios";

export const sendOtp = async (mobileNumber) => {
  const { data } = await axiosUtilityInstance.get(`/sendOtp/${mobileNumber}`);
  if (data.success) return data;
};

export const validateOTP = async (mobileNumber, otp) => {
  const { data } = await axiosUtilityInstance.post("/validateOTP", {
    mobileNumber,
    otp,
  });
  return data;
};

export const register = async (name, email, mobileNumber) => {
  try {
    const { data } = await axiosUtilityInstance.post("/register", {
      name,
      email,
      mobileNumber,
    });
    return data;
  } catch (error) {
    throw error.response?.data?.error || "Failed to login.";
  }
};

export const login = async (email, password) => {
  try {
    const response = await axiosUtilityInstance.get("/login", {
      params: {
        email: email,
        password: password,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || "Failed to login.";
  }
};

export const generateRazorPay = async (course) => {
  const { data } = await axiosUtilityInstance.post("/payment", { course });
  if (data) {
    return data;
  }
};

export const orderVerifyPayment = async (paymentData) => {
  const { data } = await axiosUtilityInstance.post("/verify-payment", {
    paymentData,
  });
  if (data) {
    return data;
  }
};

export const addPassword = async (userId, password) => {
  const { data } = await axiosUtilityInstance.put(`/add-password/${userId}`, {
    password,
  });

  if (data) {
    return data;
  }
};
